import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import { Layout, MaxWidth, SubHeader } from 'components/global'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { media } from 'utils/media'
import { WhereToWatchModal } from 'components/Modals'

const WhereToWatchBody = styled.section`
  width: 100%;
  section {
    background: var(--offWhite);
    padding-bottom: 2rem;
    h2 {
      padding: 1.25rem 0 0 0;
      color: var(--darkGrey);
      text-align: center;
      @media ${media.md} {
        padding: 2.85rem 0 0 0;
        font-size: 3rem;
      }
    }
    p {
      color: var(--darkGrey);
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }
    .images {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
      @media ${media.md} {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .mobile {
      @media ${media.md} {
        display: none;
      }
    }
    .desktop {
      display: none;
      @media ${media.md} {
        display: block;
        /* max-height: 507px; */
      }
    }
    .gatsby-image-wrapper {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 5px;
    }
  }
`

const WhereToWatch = ({ data }) => {
  const metaData = {
    title: 'Where to watch | Arnold Clark Cup',
  }
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState('')
  useEffect(() => {
    console.log(open)
  }, [open])
  const handleClick = x => {
    setModalData(x)
    setOpen(true)
  }
  return (
    <Layout title={metaData.title} $noAnimation>
      <SubHeader title="Where To watch" />
      <WhereToWatchBody>
        <MaxWidth>
          <div className="wrapper">
            <h2>2023 Partners</h2>
            <section className="images">
              <div onClick={() => handleClick('left')}>
                <StaticImage src="../../../images/map/map.jpg" alt="" />
              </div>
              <div onClick={() => handleClick('right')}>
                <StaticImage src="../../../images/map/where.jpg" alt="" />
              </div>
            </section>
          </div>
        </MaxWidth>
      </WhereToWatchBody>
      <WhereToWatchModal open={open} setOpen={setOpen} modalData={modalData} />
    </Layout>
  )
}

export default WhereToWatch
